import { useCallback, useState } from "react";
import "twin.macro";

export interface EditableTitleProps {
  title: string;
  onUpdate?: (title: string) => Promise<void>;
}

export function EditableTitle({
  onUpdate,
  title,
}: EditableTitleProps): JSX.Element {
  const [editingTitle, setEditingTitle] = useState<string | null>(null);

  const activateEditMode = () => {
    if (!onUpdate) {
      return;
    }
    if (editingTitle === null) {
      setEditingTitle(title);
    }
  };

  const handleCancel = useCallback(
    (evt?: any) => {
      evt?.stopPropagation();
      setEditingTitle(null);
    },
    [setEditingTitle],
  );

  const handleInputKey = useCallback(
    async (event) => {
      if (event.key === "Enter" && onUpdate) {
        if (!editingTitle) {
          //cancel, title cant be empty, maybe show error in future, or animate input shaking or something along those lines?
          return;
        }

        await onUpdate(editingTitle);
        setEditingTitle(null);
      }
      if (event.key === "Escape") {
        handleCancel();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [editingTitle, handleCancel, onUpdate],
  );

  function handleChangeValue(event: React.FormEvent<HTMLInputElement>) {
    setEditingTitle(event.currentTarget.value);
  }

  return (
    <span
      onClick={activateEditMode}
      className="cursor-text"
      tw="inline-block relative break-words max-w-full sm:w-[800px] sm:truncate hover:w-full hover:whitespace-normal leading-tight"
    >
      {editingTitle !== null && (
        <span tw="inline-grid grid-cols-1">
          <input
            value={editingTitle}
            placeholder="Title"
            onKeyUp={handleInputKey}
            onChange={handleChangeValue}
            onBlur={handleCancel}
            autoFocus
          />
        </span>
      )}
      {editingTitle == null && title}
    </span>
  );
}
