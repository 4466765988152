import "twin.macro";
import ErrorBoundary from "../generic/ErrorBoundary";
import { AlbumCol, MarginWrapper, MenuCol } from "../generic/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../generic/Neu";
import AlbumView from "../album/AlbumView";
import { useShare } from "../../clients/apiHooks";
import { ShareContents } from "./ShareContents";
import { Modal } from "../generic";
import AssetDetails from "../album/AssetDetails";
import { ContentWrapper } from "../collection/CollectionView";
import { EditableTitle } from "../album/EditableTitle";
import { createUpdateTitle } from "./ShareView";
import { SuspenseWrapper } from "../generic/SuspenseWrapper";
import { AppTitle } from "../Title";

export function ShareAlbumView() {
  const { shareId, assetId, albumId, collectionId } = useParams<{
    shareId: string;
    assetId: string;
    albumId: string;
    collectionId: string;
  }>();
  const { share, patchShare } = useShare(shareId);

  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(-1);
  };

  const updateName = createUpdateTitle(share, patchShare);

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the share...</div>}
    >
      <AppTitle contentTitle={share?.name} />
      <ContentWrapper tw="py-4 px-3">
        <MenuCol active={true}>
          <span tw="label">Share</span>
          <div tw="text-3xl sm:text-7xl w-full">
            <EditableTitle
              tw="sm:mb-4"
              title={share?.name || ""}
              onUpdate={updateName}
            />
          </div>
          {share && <ShareContents share={share} />}
        </MenuCol>
        <AlbumCol active={true}>
          <MarginWrapper isCenter={true}>
            <BackButton tw="mb-8" />
            <AlbumView shareId={shareId} />
          </MarginWrapper>
        </AlbumCol>
      </ContentWrapper>
      {assetId ? (
        <Modal>
          <SuspenseWrapper>
            <AssetDetails
              tw="w-full h-full"
              id={assetId}
              albumId={albumId!}
              collectionId={collectionId!}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
              hideDeleteButton
            />
          </SuspenseWrapper>
        </Modal>
      ) : null}
    </ErrorBoundary>
  );
}
