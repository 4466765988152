import { createContext, useContext, useCallback } from "react";
import {
  QueryClient,
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  uploadFile,
  getWorkspace,
  createAlbum,
  deleteAsset,
  patchAlbum,
  updateAssetMetadata,
  createCollection,
  patchCollection,
  getAlbum,
  deleteAlbum,
  getAsset,
  createShare,
  getShares,
  getAssets,
  patchShare,
  inviteToShare,
  upsertShareQuery,
  deleteShareQuery,
  getSharesForUser,
  getSharesForAlbum,
  getShare,
  getSharesForAsset,
  deleteCollection,
  deleteShare,
  unInviteFromShare,
} from "./apiClient";

import { activeUploads, FileUpload, UploadStatus } from "../atoms";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import {
  Album,
  Asset,
  Collection,
  CollectionBase,
  InviteToShareParameters,
  PatchAlbumParameters,
  PatchCollectionParameters,
  PatchShareParameters,
  Share,
  ShareQueryUpsert,
  Workspace,
  UnInviteFromShareParameters,
  Label,
  Tag,
  GetAssetsParams,
} from "./types";
import { useAtom } from "jotai";

export function useWorkspace(): {
  collections: CollectionBase[];
  hasOrganization: boolean;
  loading: boolean;
  error: boolean;
} {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const loadable = useRecoilValueLoadable(workspaceAtom);
  switch (loadable.state) {
    case "hasValue":
      return {
        collections: loadable.contents?.collections ?? [],
        hasOrganization: loadable.contents?.hasOrganization ?? false,
        loading: false,
        error: false,
      };
    case "loading":
      return {
        collections: [],
        hasOrganization: false,
        loading: true,
        error: false,
      };
    case "hasError":
      return {
        collections: [],
        hasOrganization: false,
        loading: false,
        error: true,
      };
  }
}

export function useCollections() {
  const [, setWorkspace] = useRecoilState(workspaceAtom);

  const _refreshWorkspace = async () => {
    const workspace = await getWorkspace();
    setWorkspace(workspace);
  };

  const _createCollection = async (name: string): Promise<CollectionBase> => {
    const collection = await createCollection(name);
    _refreshWorkspace();
    return collection;
  };

  const _deleteCollection = async (collectionId: string) => {
    await deleteCollection(collectionId);
    _refreshWorkspace();
  };

  return {
    deleteCollection: _deleteCollection,
    refreshWorkspace: _refreshWorkspace,
    createCollection: _createCollection,
  };
}

export function useCollection(collectionId?: string) {
  const collection = useRecoilValue(collectionSelector(collectionId));
  const refreshCollection = useRecoilRefresher_UNSTABLE(
    collectionSelector(collectionId),
  );

  const _patchCollection = async (newValues: PatchCollectionParameters) => {
    patchCollection(newValues.name, newValues.collectionId)
      .then(() => {
        refreshCollection();
      })
      .catch((err) => {
        console.error(err);
        refreshCollection();
      });
  };

  return {
    patchCollection: _patchCollection,
    collection: collection,
  };
}

export const CollectionContext = createContext<string | null>(null);
export const AlbumContext = createContext<string | null>(null);

export function useUploads(albumId: string, collectionId: string) {
  const queryClient = useQueryClient();
  const [atomFiles, setAtomFiles] = useAtom(activeUploads);

  function handleFileSelect(evt: React.FormEvent<HTMLInputElement>) {
    const fileInput = Array.from(evt.currentTarget.files || []);
    const newFiles: FileUpload[] = fileInput
      .filter(
        (f: File) =>
          !atomFiles.some(
            (enquedFile) =>
              enquedFile.state !== UploadStatus.FAILURE &&
              enquedFile.file.name === f.name,
          ),
      )
      .map((f: File) => ({
        file: f,
        albumId: albumId,
        collectionId: collectionId,
        state: UploadStatus.IN_PROGRESS,
        id: uuidv4(),
      }));

    setAtomFiles(atomFiles.concat(newFiles));

    newFiles.forEach((fileUpload) => {
      uploadFile(fileUpload)
        .then((response) => {
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.SUCCESS },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .catch((error) => {
          console.error(error);
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.FAILURE },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .finally(async () => {
          refreshAssets(queryClient);
        });
    });
  }

  const removeFile = useCallback(
    (filename: string) => {
      setAtomFiles((atomFiles) =>
        atomFiles.filter((fileUpload) => fileUpload.file.name !== filename),
      );
    },
    [setAtomFiles],
  );

  return { atomFiles, handleFileSelect, removeFile };
}

const albumsRefreshAtom = atom({
  key: "AlbumsRefresh",
  default: 0,
});

export function useAlbumsHook(collectionId: string) {
  const refreshAlbums = useRecoilCallback(({ set }) => () => {
    set(albumsRefreshAtom, (value) => value + 1);
  });

  const _createAlbum = async (name: string): Promise<Album> => {
    const album = await createAlbum(collectionId, name);
    refreshAlbums();
    return album;
  };

  const _deleteAlbum = async (albumId: string) => {
    await deleteAlbum(collectionId, albumId);
    refreshAlbums();
  };

  return {
    createAlbum: _createAlbum,
    deleteAlbum: _deleteAlbum,
    refreshAlbums,
  };
}

export function useAlbumsRefresh(): unknown {
  return useRecoilValue(albumsRefreshAtom);
}

export function useAlbumShares(collectionId: string, albumId: string) {
  const query = useQuery({
    queryKey: ["albumShares", collectionId, albumId],
    queryFn: () => getSharesForAlbum(collectionId, albumId),
    throwOnError: true,
  });
  return { shares: query.data };
}

export function useAlbum(collectionId: string, albumId: string) {
  const queryClient = useQueryClient();
  const albumQuery = useQuery({
    queryKey: ["albumById", collectionId, albumId],
    queryFn: () => getAlbum(collectionId, albumId),
    throwOnError: true,
  });
  const refreshAlbum = albumQuery.refetch;
  const refreshAlbumSharedIn = () => {
    queryClient.invalidateQueries({
      queryKey: ["albumShares", collectionId, albumId],
    });
  };
  const { refreshAlbums } = useAlbumsHook(collectionId);

  const _patchAlbum = async (newValues: PatchAlbumParameters) => {
    try {
      await patchAlbum(collectionId, albumId, newValues);
    } catch (err) {
      console.error(err);
    } finally {
      await refreshAlbum();
      refreshAlbums();
      refreshAssets(queryClient);
      refreshAlbumSharedIn();
    }
  };

  return { patchAlbum: _patchAlbum, album: albumQuery.data };
}

/*
  These atoms (workspace, albums and assets) correspond to our backend entities and should follow those definitions
  closely.
  Everything else we need (for convenience in the UI) should be derived selectors from those.
*/

export const workspaceAtom = atom<Workspace | null>({
  key: "Workspace",
  default: selector<Workspace | null>({
    key: "Workspace/Default",
    get: async () => {
      return await getWorkspace();
    },
  }),
});

const sharesAtom = atom<Share[] | null>({
  key: "Shares",
  default: selector<Share[] | null>({
    key: "Shares/Default",
    get: async () => {
      return await getShares();
    },
  }),
});

const userSharesAtom = atom<Share[] | null>({
  key: "UserShares",
  default: selector<Share[] | null>({
    key: "UserShares/Default",
    get: async () => {
      return await getSharesForUser();
    },
  }),
});

export const albumAssetPreviewSelector = selectorFamily<
  Asset[],
  { collectionId: string; albumId: string }
>({
  key: "AlbumAssetsFirstPage",
  get:
    ({ collectionId, albumId }) =>
    async () => {
      const result = await getAssets({
        collectionId,
        albumId,
        limit: 5,
        sortOrder: "DESC",
      });
      return result.assets;
    },
});

export function useAssets(
  {
    collectionId,
    albumId,
    searchQuery,
    offset,
    limit,
    sortOrder,
    signal,
    shareId,
  }: GetAssetsParams,
  gcTime?: number,
) {
  const fetchedAssetsQuery = useSuspenseQuery({
    queryKey: [
      "assets",
      collectionId,
      albumId,
      searchQuery,
      offset,
      limit,
      sortOrder,
      signal,
      shareId,
    ],
    queryFn: async () => {
      if (!albumId && !searchQuery) {
        // kind of hacky: on the collection view, if there is no search query, we don't want to show assets
        return { assets: [], assetsCount: 0 };
      }
      return await getAssets({
        collectionId,
        albumId,
        searchQuery,
        offset: offset,
        limit,
        sortOrder,
        signal: signal,
        shareId,
      });
    },
    gcTime: gcTime || 60,
  });

  return {
    assets: fetchedAssetsQuery.data,
    refetchAssets: fetchedAssetsQuery.refetch,
    isLoading: fetchedAssetsQuery.isLoading,
  };
}

export const refreshAssets = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["assets"] });
};

export function useAsset({
  assetId,
  albumId,
  collectionId,
}: {
  assetId: string;
  albumId: string;
  collectionId: string;
}) {
  const queryClient = useQueryClient();

  const assetQuery = useSuspenseQuery({
    queryKey: ["assetById", collectionId, albumId, assetId],
    queryFn: () => getAsset(collectionId, albumId, assetId),
  });

  const _deleteAsset = useMutation({
    mutationFn: async () => {
      await deleteAsset(collectionId, albumId, assetId);
    },
    onSuccess: () => refreshAssets(queryClient),
  });

  const _updateAssetMetadata = useMutation({
    mutationFn: async (metadata: {
      labels?: Label[];
      tags?: Tag[];
      name?: String;
    }) => {
      if (!collectionId || !albumId) {
        return;
      }
      await updateAssetMetadata(metadata, collectionId, albumId, assetId);
    },
    onSuccess: () => {
      refreshAssets(queryClient);
      assetQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: ["sharesForAsset", collectionId, albumId, assetId],
      });
    },
  });

  return {
    asset: assetQuery.data,
    deleteAsset: _deleteAsset,
    updateAssetMetadata: _updateAssetMetadata,
  };
}

export function useAssetShares({
  assetId,
  albumId,
  collectionId,
}: {
  assetId: string;
  albumId: string;
  collectionId: string;
}) {
  const assetSharesQuery = useSuspenseQuery({
    queryKey: ["sharesForAsset", collectionId, albumId, assetId],
    queryFn: () => getSharesForAsset(collectionId, albumId, assetId),
  });
  return { sharesForAsset: assetSharesQuery.data };
}

export const collectionSelector = selectorFamily<
  Collection | null,
  string | null | undefined
>({
  key: "Collection",
  get:
    (collectionId) =>
    async ({ get }) => {
      const collections = get(workspaceAtom);
      const collection = collections?.collections.filter(
        (collection) => collection.id === collectionId,
      );
      if (!collectionId || !collection?.length) {
        return null;
      }

      return {
        name: collection[0].name,
        id: collectionId,
      };
    },
});

export function useShares() {
  const [shares, setShares] = useRecoilState(sharesAtom);
  const [userShares, setUserShares] = useRecoilState(userSharesAtom);

  const _refreshShares = async () => {
    const shares = await getShares();
    const userShares = await getSharesForUser();
    setUserShares(userShares);
    setShares(shares);
  };

  const _createShare = async (name: string): Promise<Share> => {
    const share = await createShare(name);
    _refreshShares();
    return share;
  };

  return {
    createShare: _createShare,
    refreshShares: _refreshShares,
    userShares: userShares,
    shares,
  };
}

export function useShare(shareId?: string) {
  const queryClient = useQueryClient();

  const shareQuery = useQuery({
    queryKey: ["shareById", shareId],
    queryFn: shareId ? () => getShare(shareId) : skipToken,
    throwOnError: true,
  });
  const { refreshShares } = useShares();

  const _deleteShareQuery = useMutation({
    mutationFn: (payload: { shareQueryId: string; shareId: string }) =>
      deleteShareQuery(payload.shareQueryId, payload.shareId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shareById", shareId],
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  const _upsertShareQuery = (payload: ShareQueryUpsert) => {
    return upsertShareQuery(payload)
      .then(() => {
        shareQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
        shareQuery.refetch();
        throw err;
      });
  };

  const _patchShare = (payload: PatchShareParameters) => {
    return patchShare(payload.name, payload.shareId)
      .then(() => {
        shareQuery.refetch();
        refreshShares();
      })
      .catch((err) => {
        console.error(err);
        shareQuery.refetch();
        refreshShares();
      });
  };

  const _inviteToShare = async (payload: InviteToShareParameters) => {
    try {
      await inviteToShare(payload.emails, payload.shareId);
      await shareQuery.refetch();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const _unInviteFromShare = useMutation({
    mutationFn: (payload: UnInviteFromShareParameters) =>
      unInviteFromShare(payload.email, payload.shareId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shareById", shareId],
      });
    },
  });

  const _deleteShare = async (shareId: string) => {
    try {
      await deleteShare(shareId);
    } catch (err) {
      console.error(err);
      throw err;
    }
    refreshShares();
  };

  return {
    inviteToShare: _inviteToShare,
    unInviteFromShare: _unInviteFromShare,
    patchShare: _patchShare,
    upsertShareQuery: _upsertShareQuery,
    deleteShareQuery: _deleteShareQuery,
    share: shareQuery.data,
    deleteShare: _deleteShare,
  };
}
