import tw, { styled } from "twin.macro";

import { PropsWithChildren, useCallback, useEffect } from "react";

import { NeuButton } from "./Neu";

export const Backdrop = tw.div`fixed top-0 left-0 w-full h-full bg-grey-lightest bg-opacity-95 backdrop-blur-sm`;

type ModalProps = PropsWithChildren<{
  className?: string;
  centered?: boolean;
  onClose?: () => void;
}>;

const ContentWrapper = styled.div(
  ({ centered }: Pick<ModalProps, "centered">) => [
    centered
      ? tw`inline-block absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2 max-w-full overflow-auto`
      : tw`w-full h-full relative`,
  ],
);

export const Modal = function ({
  centered,
  className,
  onClose,
  children,
}: ModalProps): JSX.Element {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Escape" && onClose) {
        onClose();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [onClose],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div tw="fixed top-0 left-0 w-full h-full overflow-hidden">
      <Backdrop onClick={onClose} />
      <ContentWrapper centered={centered} className={className}>
        {children}
      </ContentWrapper>
      {onClose && (
        <div tw="fixed top-0 right-0 p-4 text-center" onClick={onClose}>
          <NeuButton round icon="mi-close">
            close
          </NeuButton>
        </div>
      )}
    </div>
  );
};

interface IconProps {
  className?: string;
  onClick?: (evt: any) => void;
  t: string;
}

export function Icon(props: IconProps): JSX.Element {
  return (
    <i className={`mi-${props.t} ${props.className}`} onClick={props.onClick} />
  );
}
