import "twin.macro";
import { Link, useSearchParams } from "react-router-dom";

export type PaginationProps = {
  pages: number;
  className?: string | undefined;
  param?: string;
};

export function Pagination(props: PaginationProps) {
  const [params] = useSearchParams();
  if (props.pages <= 1) {
    return null;
  }
  const currentPage = getPage(params, props.param ?? "page");
  const pages = getPageNumbers(props.pages, currentPage);

  return (
    <div tw="space-x-4" className={props.className}>
      {pages.map((page) =>
        !page ? (
          <span>...</span>
        ) : (
          <Link
            key={page}
            to={"?" + changeParam(params, props.param ?? "page", String(page))}
            style={{
              textDecoration: page === currentPage ? "underline" : undefined,
            }}
          >
            {page}
          </Link>
        ),
      )}
    </div>
  );
}

export function getPage(params: URLSearchParams, param = "page"): number {
  return getNumberParam(params, param, 1);
}

// TODO replace with shadcn when it's set up
const getPageNumbers = (totalPages: number, currentPage: number): number[] => {
  if (totalPages <= 5) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const pageNumbers: number[] = [1];

  let startPage = Math.max(2, currentPage - 1);
  let endPage = Math.min(currentPage + 1, totalPages - 1);

  if (currentPage <= 3) {
    endPage = 4;
  } else if (currentPage >= totalPages - 2) {
    startPage = totalPages - 3;
  }

  if (startPage > 2) pageNumbers.push(0);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  if (endPage < totalPages - 1) pageNumbers.push(0);

  pageNumbers.push(totalPages);

  return pageNumbers;
};

function changeParam(
  params: URLSearchParams,
  name: string,
  newValue: string,
): string {
  const newParams = new URLSearchParams(params);
  newParams.set(name, newValue);
  return newParams.toString();
}

function getNumberParam(
  params: URLSearchParams,
  name: string,
  defaultValue: number,
): number {
  const param = params.get(name);
  if (param === null) {
    return defaultValue;
  }
  const parsed = parseInt(param);
  if (isNaN(parsed)) {
    return defaultValue;
  }
  return parsed;
}
